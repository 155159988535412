import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_3/sub_5/subSlide';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-13-6"}}) {
        body
      }
      file(relativePath: { eq: "chapter_4/sub_13/mobile/slides-bg/7.jpg"}) {
        childImageSharp {
            fixed(width: 1920) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
      }
    }
  `);

  return (
    <Template query={query} />
  );
};


export default Slide;
