import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/ua/chapter_4/sub_13/slide1';

import WrapperMobile from 'src/slides/mobile/ua/chapter_4/sub_13/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Що сталося після перемоги I Варшавська битва" description="Оборона Львова, битва під Задвір'ям, бій на Німані - перемога під Варшавою не закінчила війну." lang="ua" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="Що сталося після перемоги I Варшавська битва" description="Оборона Львова, битва під Задвір'ям, бій на Німані - перемога під Варшавою не закінчила війну." lang="ua" />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};
export default Page;
